.detTable {
  display: "inline-block";
  background-color: "#f53c56";
  border-radius: "50%";
  width: 10;
  height: 10;
  margin-right: 5;
}


