html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f1f3f9 !important;
}

.App {
  margin: 0px;
  padding: 0px;
  width: 100%;
  background-color: #f1f3f9;
}

.cartHeader {
  font-family: Roboto-Regular;
  font-size: 18px;
  text-align: left;
  color: #989fa4;
}

.tabcomp {
  display: inline-block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.dropAction {
  margin-left: 2px;
  margin-top: 2px;
  background-color: #f7fafc;
  box-shadow: 0 3px 6px 0 rgba(44, 40, 40, 0.11);
  border-radius: 16 / 2;
}

.droppedAct {
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 50px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  right: 50px;
  .droppedaction {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
}

.tabAction {
  margin-left: 2px;
  margin-top: 2px;
  background-color: #f7fafc;
  box-shadow: 0 3px 6px 0 rgba(44, 40, 40, 0.11);
  border-radius: 1em;
}

.inputErWr {
  .Mui-error {
    font-size: 14px !important;
    color: #ad175b !important;
  }
}
.blue-btn {
  border: 2px solid #174ea2;
  background: #174ea2 !important;
  color: white;
  font-size: 7px;
  margin-left: -25px;
}

.serviceCont {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  border-radius: 25px;
  color: #ffffff;
  cursor: pointer;
  width: 70px;
  height: 22px;
}

.double-box {
  height: auto;
  width: auto;
  border-radius: 29px;
  background-color: #f9fafc;
  box-shadow: 0 3px 20px 0 rgb(0 0 0 / 3%);
  bottom: -7px;
  left: 7%;
  position: absolute;
  right: 7%;
  z-index: 13;
}

.carousel-double-box {
  height: 10px;
  width: auto;
  border-radius: 29px;
  background-color: #f9fafc;
  box-shadow: 0 3px 20px 0 rgb(0 0 0 / 3%);
  bottom: -5px;
  left: 7%;
  position: absolute;
  right: 7%;
  z-index: -4;
}

.offline {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
  justify-content: center;
  align-items: center;
  color: rgb(157, 48, 48);
  font-size: 28px;
  font-weight: bold;
  z-index: 99999999;
  background-color: #0000006b;
}

.uploadButtonCss {
  width: auto;
  height: 22px;
  background-color: #174ea2;
  border: solid 1px #174ea2;
  border-radius: 2px;
  font-size: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 8px 0 8px;
  cursor: pointer;
}

.form-submission {
  width: 117px;
  height: 40px;
  box-shadow: 0 3px 20px 0 rgb(0 0 0 / 3%);
  background-color: #174ea2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #fff;
  font-weight: bolder;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s linear;
  &:hover {
    box-shadow: 0 0 9px 2px #00000061;
  }
}

.order-not-confirm {
  width: 98%;
  height: 40px;
  padding: 0 15px 0 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  div {
    border: 1px solid #bbbbbb;
    box-shadow: 0 3px 20px 0 rgb(0 0 0 / 3%);
    display: flex;
    border-radius: 4px;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-left: 15px;
  }
  p {
    font-size: 15px;
    font-weight: lighter;
    color: black;
  }
}

.remove-shading {
  &:active {
    background-color: transparent !important;
  }
  &:focus {
    background-color: transparent !important;
  }
}

//header

.profile-username-header {
  span {
    width: auto;
    display: block;
    max-width: 5em;
    min-width: auto;
    margin: 0 !important;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

.header-profile-image {
  span {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

//header

.global-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  z-index: 999999999;
  overflow: auto;
}

.crop-area {
  padding-top: 15px;
  position: relative;
  .react-crop-wrapper {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    position: relative;
    height: 50vh;
  }

  .cropped-image-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 15px;
  }

  .crop-controls {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    .crop-close {
      border: 1px solid gray;
      padding: 8px 15px 8px 15px;
      text-align: center;
      transition: all 0.3s linear;
      line-height: 1;
      height: max-content;
      cursor: pointer;
      font-size: 15px;
      &:hover {
        box-shadow: 2px 2px 8px 1px #00000038;
      }
    }
    .crop-set {
      margin-left: 15px;
      border: 1px solid gray;
      padding: 8px 15px 8px 15px;
      text-align: center;
      cursor: pointer;
      font-size: 15px;
      line-height: 1;
      height: max-content;
      transition: all 0.3s linear;
      &:hover {
        box-shadow: 2px 2px 8px 1px #00000038;
      }
    }
  }
}

///modal
.pure-modal-backdrop {
  z-index: 999999999 !important;
}

.pure-modal {
  // min-width: min-content;
  // width: auto !important;
  // max-width: 60% !important;
  .panel.panel-default {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 992px) {
  .pure-modal {
    min-width: min-content;
    max-width: 85% !important;
    width: 90% !important;
  }
}

//modal

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

//breacrumb
.breadcrumb-container {
  width: 100%;
  display: flex;
  align-items: center;
}
//breacrumb

//cusror
.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer !important;
}
