.containerLeft {
  background-image: linear-gradient(160deg, #2b51b4 35%, #1c3faa 85%);
  // -webkit-clip-path: polygon(0% 0%, 86% 0, 73% 32%, 92% 99%, 0% 100%);
  // clip-path: polygon(0% 0%, 86% 0, 79% 42%, 92% 100%, 0% 100%);
  background-repeat: no-repeat;
  background-size: 100%, 100%;
}

.password-meter {
  width: 25%;
  div {
    background-color: #edf2f6;
    height: 4px;
    border-radius: 2px;
  }
}
.password-meter.active {
  div {
    background-color: #2ec4b6;
  }
}

