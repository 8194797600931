.dashboardContainer {
  background-color: #f1f5f8;
  padding: 32px 5px 32px 5px;
}

.athen-product-title {
  font-size: 22px;
  font-weight: bold;
  font-family: Roboto-Bold;
  color: #000000;
  line-height: 1.05;
  text-align: left;
  width: 100%;
}

.digital-risk-title {
  font-size: 20px;
  font-weight: bold;
  color: #707070;
  line-height: 1.05;
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 0.25rem;
  font-family: Roboto-Bold;
}

.digital-risk-assessment {
  font-size: 20px;
  font-weight: bold;
  color: #707070;
  line-height: 1.05;
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 26px;
  font-family: Roboto-Bold;
}

.thunderbolt-service-wrapper {
  padding: 0 20px 0 20px;
  width: 100%;
}

.dashboardPaperContainer {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  .product-first-card {
    display: flex;
  }
  .product-second-card {
    display: flex;
    margin-left: 30px;
  }
}

.dashboardBottomContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(44, 40, 40, 0.11);
  background-color: #ffffff;
  margin-bottom: 40px;
  margin-top: 40px;
}

.dashboardQuestionContainer {
  display: flex;
  width: 100%;
}

.dashboardFormContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  margin-top: 20px;

  .form-left {
    width: 50%;
  }

  .form-right {
    width: 50%;
  }
}

.dashboardFormFieldSize {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  border-left: 3.5px solid black;
  margin-left: 25px;
  margin-top: 12px;
}

.dashboardButtonContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.dtbBottomContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
  position: relative;
  box-shadow: 0 3px 6px 0 rgba(44, 40, 40, 0.11);
  border-radius: 10px;
}

@media only screen and (max-width: 1100px) {
  .dtbBottomContainer {
    box-shadow: none !important;
    background-color: inherit !important;
    .thunderbolt-service-wrapper {
      background-color: white;
      padding: 10px;
      border-radius: 10px;
    }
  }

  .dashboardPaperContainer {
    display: block;
    width: 100%;
    .product-first-card {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .product-second-card {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 10px;
      margin-left: 0;
    }
  }

  .thunderbolt-service-wrapper {
    padding: 0 10px 0 10px;
    display: flex;
    flex-direction: row-reverse;
    .w-50 {
      padding-right: 10px;
    }
  }

  .dashboardFormRightContainer {
    width: 50%;
  }

  .digital-risk-assessment {
    font-size: 17px;
  }

  .digital-risk-title {
    font-size: 17px;
  }

  .dashboardPaperContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .athen-product-title {
    text-align: center;
  }
}

@media only screen and (max-width: 820px) {
  .dashboardFormContainer {
    display: block;
    margin-top: 20px;
    .form-left {
      width: 100%;
    }

    .form-right {
      width: 100%;
    }
  }

  .athen-product-title.form-title {
    display: flex !important;
    justify-content: center !important;
    margin-left: 0;
    margin-right: 0;
    padding: 0 10px 0 10px;
  }

  .dashboardContainer {
    height: auto;
  }

  .dashboardBottomContainer {
    background-color: #f1f5f8;

    flex-wrap: nowrap !important;

    .digital-risk-assessment {
      width: 100%;
      text-align: center;
      margin-left: 0;
      margin-bottom: 7px;
      color: blue;
    }

    .digital-risk-title {
      width: 100% !important;
      text-align: center !important;
      color: blue;
      margin-top: 20px;
    }

    .fieldset {
      height: fit-content;
      width: 100% !important;
      padding: 10px !important;
      margin-top: 10px;
      background-color: white;
      border-radius: 8px;
    }

    .divBorder {
      margin-left: 0 !important ;
    }
  }

  .dashboardFormLeftContainer {
    width: 100%;
    height: 50%;
  }
  .dashboardFormRightContainer {
    width: 100%;
    height: 50%;
  }

  .thunderbolt-service-wrapper {
    display: block !important;
    .w-50 {
      width: 100% !important;
      float: none !important;
      margin: 10px 0 10px 0;
    }
    .dashboardFormFieldSize {
      width: 100% !important;
      .thunderbolt-fieldset {
        width: 100% !important;
      }
    }
  }
}
