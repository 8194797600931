.mobile-box-service {
  position: relative;
  width: 100%;
  max-width: 350px;
  border-radius: 7px;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  display: none;
  .image-section {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: auto;
      height: 250px;
    }
  }
  .title-section {
    width: 100%;
    padding-top: 10px;
    font-size: 25px;
    font-weight: bold;
    color: #002147;
    text-align: center;
  }
  .supportive {
    height: 10px;
    width: auto;
    border-radius: 29px;
    background-color: #f9fafc;
    box-shadow: 0 3px 20px 0 rgb(0 0 0 / 3%);
    bottom: -5px;
    left: 7%;
    position: absolute;
    right: 7%;
    z-index: -4;
  }
}

.containerCommon {
  display: flex;
  flex-direction: row;
  height: 120px;
  width: 240px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(44, 40, 40, 0.11);
}

.commonLeftContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
}

.commonTextContainer {
  display: flex;
  height: 70%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-family: Roboto-Regular;
  font-size: 18px;
  line-height: 1.11;
  color: #4d4f5c;
}

.commonButtonContainer {
  display: flex;
  margin-left: -20px;
  margin-bottom: -20px;
  width: 70px;
  height: 22px;
  border-radius: 25px;
  background-color: #164ee5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  font-size: 7px;
  line-height: 1.71;
  text-align: left;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-color: 2px solid rgb(251, 99, 64);
}

.commonRightContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 50%;
  align-items: center;
  justify-content: center;
}

.commonPageTitleContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
}

.commonPageTitleLeftContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}

.home {
  width: 64px;
  height: 38px;
  margin: 30px 21px 20px 30px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.commonPageTitleContentContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}

.commonPageTitleContentContainerTwo {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-left: 50px;
}

.card_title {
  width: 54px;
  height: 27px;
  margin: 20px 57px 27px 3px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: #4d4f5c;
}

@media only screen and (max-width: 820px) {
  .dashboardContainer {
    .proBoxes {
      display: none;
    }
    .mobile-box-service {
      display: block;
    }
  }
}

@media only screen and (max-width: 480px) {
  .commonPageTitleLeftContainer {
    min-width: 80%;
  }
  .commonButtonContainer {
    width: 80%;
  }
}
