@import '../../../sass/variables.scss';

.sidebar-header-section {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  background-color: inherit;
  .sidebar-header-wrapper {
    width: 100%;
    height: auto;
    position: relative;
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-logo {
      cursor: pointer;
      width: 160px;
      height: auto;
    }
    .collapsed-view {
      width: 60px;
      height: auto;
    }
    .sidebar-icon {
      position: absolute;
      right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.sidebar-item-wrapper {
  width: 100%;
  // background-color: #f1f3f9;

  .menu-items {
    width: 100%;
    height: 47px;
    background-color: #1c3faa;
    display: flex;
    align-items: center;
    transition: all 0.3s linear;
    padding-left: 20px;
    &:hover {
      // background-color: #00000010;
      cursor: pointer;
    }
    .menu-items-child {
      width: 100%;
      height: 100%;
      padding: 10px 30px 10px 30px;
      display: flex;
      align-items: center;
      .item-image {
        height: 100%;
        width: auto;
        display: flex;
        align-items: center;
        .image-white {
          width: 20px;
          height: auto;
          display: block;
        }
        .image-black {
          width: 20px;
          height: auto;
          display: none;
        }
      }
      .item-name {
        font-size: 14px;
        padding-left: 15px;
        color: white;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .menu-items.disabled {
    &:hover {
      cursor: default;
      background-color: #1c3faa !important;
    }
  }

  .menu-items.borderless-top {
    border-top-right-radius: 10px;
  }

  .menu-items.borderless-bottom {
    border-bottom-right-radius: 10px;
  }

  .menu-items.active {
    .menu-items-child {
      background-color: #f1f5f8;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    // &:before {
    //   border-right: 0;
    //   -moz-border-radius: 0 0 0 5px;
    //   -webkit-border-radius: 0 0 0 5px;
    //   right: -41px;
    // }
    // &:after {
    //   border-left: 0;
    //   -moz-border-radius: 0 0 5px 0;
    //   -webkit-border-radius: 0 0 5px 0;
    //   left: -41px;
    //   content: "";
    //   width: 40px;
    //   height: 30px;
    //   border: 1px solid red;
    //   position: absolute;
    //   bottom: -3px;
    //   border-top: 0;
    // }
    // &:before {
    //   content: "";
    //   width: 40px;
    //   height: 30px;
    //   border: 1px solid red;
    //   position: absolute;
    //   bottom: -3px;
    //   border-top: 0;
    // }
    .item-name {
      color: #000000 !important;
    }
    .item-image {
      .image-white {
        display: none !important;
      }
      .image-black {
        display: block !important;
      }
    }
  }
}

@media only screen and (min-width: $large) {
  .sidebar-logo.collapsed-view {
    width: 60px !important;
    height: auto;
  }
  .sidebar-logo {
    width: auto !important;
  }
}
