.stepper {
    text-align: left !important;
    span {
        span {
            text-transform: uppercase;
            font-family: Roboto;
            font-size: 15px;
            font-weight: bold !important;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #000;
        }
    }
}

//flex column center
.fcc {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rightside {
    display: flex;
    flex-direction: row;
    align-items: center;
    transform: translate(-50%);
    margin-right: -16px;
}

.mfr {
    margin-right: 16px !important;
    text-align: left !important;
    font-size: 16px !important;
    text-transform: uppercase;
}

.leftside {
    display: flex;
    flex-direction: row;
    align-items: center;
    transform: translate(50%);
    margin-left: -16px;
}

//margin fontsize left align
.mfl {
    margin-left: 16px !important;
    text-align: left !important;
    font-size: 16px !important;
    text-transform: uppercase;
}

.accomplished {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: #5f5f5f;
    border: 5px solid #213360;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);
}

.notaccomplished {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: #ffffff;
    border: 3.5px solid black;
    box-shadow: none;
}

.borderAccom {
    border-left: 4px solid #808080;
    height: 36px;
    background-color: #5f5f5f;
}

.notBorderAccom {
    border-left: 4px solid #c0c0c0;
    height: 36px;
    background-color: #ffffff;
}

@media only screen and (max-width: 720px) {
    .mfr {
        font-size: 13px !important;
    }
    .mfl {
        font-size: 13px !important;
    }
}
