.header-item-profile {
  .name-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #000000;
    background-color: #dee7ef;
    text-transform: uppercase;
  }
}
