.dpdCustomersContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  background: linear-gradient(to top, #f1f3f9 calc(100% - 230px), #174ea2 1%);
}

.dpdStatusTableContainer {
  display: flex;
  flex-direction: column;
  width: 85%;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(44, 40, 40, 0.11);
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
}

.dpdTitleContainer {
  display: flex;
  flex-direction: row;
  height: 15%;
  width: 95%;
  align-items: center;
  justify-content: space-between;
}

.dpdTableContainer {
  display: flex;
  flex-direction: row;
  height: 70%;
  width: 95%;
}

.dpdTitle {
  display: flex;
  flex-direction: row;
  height: 80%;
  width: 10%;
  justify-content: flex-start;
  align-items: center;
  font-family: Roboto-Regular;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: left;
  color: #172b4d;
}

.dpdExportButton {
  display: flex;
  width: 10%;
  height: 50%;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(44, 40, 40, 0.11);
  border: solid 1px #000000;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  font-family: Roboto-Regular;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.dpdPaginationContainer {
  display: flex;
  flex-direction: row;
  height: 15%;
  width: 95%;
  align-items: flex-start;
  justify-content: flex-end;
}
