.service-wrapper {
  width: 100%;
  box-shadow: 0 3px 6px 0 rgb(44 40 40 / 11%);
  background-color: #ffffff;
  height: auto;
  margin-bottom: 32px;
  padding: 20px;
  border-radius: 5px;
  .service-detail-wrapper {
    width: 100%;
    display: flex;
    .service-detail {
      width: 75%;
      display: flex;
      justify-content: space-between;
      .servie-name {
        color: #393e46;
        font-size: 18px;
      }
      .service-step {
        width: inherit;
      }
    }
    .file-container {
      width: 25%;
      border-radius: 25px;
      box-shadow: 0 3px 6px 0 rgba(23, 23, 23, 0.18);
      border: solid 1px #707070;
      background-color: #f7fafc;
      padding-bottom: 24px;

      .file-loading {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .file-uploaded-with {
        width: 100%;
        height: auto;
        display: table;
        padding: 10px;
        .file-uploaded-text {
          font-size: 18px;
          margin-top: 10px;
          text-align: center;
          font-weight: bold;
        }
        .file-container-wrapper {
          width: 100%;
          margin-top: 20px;
          overflow: hidden;
          border: 1px solid #bbb;
          border-radius: 8px;
          padding: 10px;

          .update-form-submit {
            margin-top: 5px;
            display: flex;
            justify-content: center;
        
            button {
              padding: 5px 12px;
              background-color: #2141a8;
              font-size: 13px;
              border-radius: 8px;
              color: #fff;
              display: flex;
              margin-left: 10px;
              border: 0;
              outline: none;
              box-shadow: 0;
              align-items: center;
              transition: all 0.3s linear;
              cursor: pointer;
              cursor: pointer;
              &:hover {
                background-color: #1a3694;
              }
            }
        
            .bg-pink-light{
              background-color: #dd4264;
              &:hover{
                background-color: #c53050;
              }
            }

            .bg-green-light{
              background-color: #82b56f;
              &:hover{
                background-color: #82b56f;
              }
            }

            .update-modal-close {
              padding: 5px 12px;
              background-color: #dd4264;
              font-size: 13px;
              border-radius: 8px;
              color: #fff;
              display: flex;
              margin-left: 10px;
              border: 0;
              outline: none;
              box-shadow: 0;
              align-items: center;
              transition: all 0.3s linear;
              cursor: pointer;
              &:hover{
                background-color: #c53050;
              }
            }
          }
        

          .file-icon {
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .file-upload-time {
            margin-top: 10px;
            font-size: 12px;
            height: auto;
            color: #000;
            text-align: center;
            cursor: pointer;
            &:hover {
              color: blue;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .service-detail-wrapper {
    display: block !important;
    .service-detail {
      width: 100% !important;
      display: block !important;
      .service-step {
        margin-top: 20px !important;
      }
    }
    .file-container {
      width: 100% !important;
      margin-top: 10px !important;
    }
  }
}

.close-modal-alt {
  background-color: #91374d;
  padding: 5px 12px;
  font-size: 13px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  height: 32px;
  align-items: center;
  display: flex;
  width: 100px;
  transition: all 0.3s linear;
  cursor: pointer;
  justify-content: center;
  margin-left: 10px;
  &:hover {
    background-color: #7e2b40;
  }
}
