.base-card-container {
  .profile-container {
    width: 100%;
    box-shadow: 0 3px 6px 0 rgb(44 40 40 / 11%);
    background-color: #ffffff;
    height: auto;
    margin-bottom: 32px;
    border-radius: 5px;
  }
}

.profile-header {
  height: 50px;
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: solid 1px #edf2f6;
  padding-left: 20px;

  font-size: 17px;
  font-weight: 500;
  line-height: 1.18;
  color: #2d3748;
}

.profile-body {
  padding: 20px;
  display: flex;
  position: relative;
  .profile-absolute-loader {
    position: absolute;
    background-color: #ffffffa3;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-section {
    width: 30%;
    display: flex;
    justify-content: center;
    padding: 20px 0 20px 0;
    border-radius: 8px;
    border: solid 1px #a9abae;
    height: min-content;

    .image-section-child {
      width: 200px !important;

      .profile-image {
        width: 100%;
        height: min-content;
        position: relative;
        width: 100%;
        height: 150px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
        svg {
          position: absolute;
          right: -7px;
          top: -23px;
          cursor: pointer;
          width: 20px;
          height: 50px;
        }
      }

      .default-image-profile{
        width: auto !important;
        margin-left: auto;
        margin-right: auto;
      }

      .profile-change-button {
        width: 100%;
        height: auto;
        margin-top: 15px;
        label {
          width: 100%;
          height: 40px;
          background-color: #1f40aa;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          color: #fff;
          line-height: 2.93;
          font-size: 15px;
          cursor: pointer;
        }
      }
    }
  }

  .form-section {
    width: 70%;
    padding-left: 20px;

    .profile-input-box {
      margin-bottom: 15px;
      .profile-label {
        font-size: 15px;
        color: #2d3848;
        margin-bottom: 7px;
      }

      .profile-number-wrapper {
        width: 100%;
        display: flex;
        .profile-number-section {
          width: 65%;
        }
        .profile-otp-button {
          width: max-content;
          button {
            width: 100%;
            height: 40px;
            background-color: #1f40aa;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            color: #fff;
            font-size: 15px;
            margin-left: 20px;
            width: 110px;
            height: 41px;
          }
        }
      }
    }

    .profile-form-submit {
      width: 100%;
      margin-top: 15px;
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-end;
      button {
        background-color: #1f40aa;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        color: #fff;
        font-size: 15px;
        width: 80px;
        height: 41px;
      }
    }
  }
}

.profile-input-box {
  select {
    width: 100%;
    background-color: transparent;
    padding: 12px 15px 11px 14px;
    border-radius: 5px;
    border: solid 1px #a9abae;
    height: 41px;
    display: flex;
    align-items: center;
    color: #2e3949;
  }
  textarea {
    height: auto !important;
  }
}

@media screen and (max-width: 992px) {
  .image-section-child {
    width: 80% !important;
  }
}

@media screen and (max-width: 1100px) {
  .image-section-child {
    width: 80% !important;
  }

  .profile-body {
    display: block;
    .image-section {
      width: 100%;
    }
    .form-section {
      width: 100%;
      margin-top: 15px;
      padding-left: 0;
    }
  }
}

.profile-custom-input {
  width: 100%;
  background-color: transparent;
  padding: 12px 15px 11px 14px;
  border-radius: 5px;
  border: solid 1px #a9abae;
  height: 41px;
  display: flex;
  align-items: center;
  color: #2e3949;
  &:focus {
    border: solid 1px #a9abae !important;
    outline: none !important;
  }
  ::placeholder {
    color: #8f9297;
  }
}

.input-error-outline {
  border: solid 1px #f44336 !important;
  &:focus {
    border: solid 1px #f44336 !important;
    outline: none !important;
  }
}

.profile-custom-textarea {
  resize: none;
  height: 70px;
}

.profile-form-error {
  margin-top: 3px;
  font-size: 13px;
  color: red;
}

.loader-box {
  width: 100%;
  min-height: 15em;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
