.athena-product-wrapper {
  width: 100%;
  height: auto;

  .service-loading{
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .product-tag-block {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    .product-tag-image {
      width: 330px;
      display: flex;
      justify-content: center;
      height: 220px;
      background-color: #ffffff;
      border-radius: 8px;
      img {
        width: 220px;
        height: auto;
      }
    }
  }

  .service-block {
    width: 100%;
    border-radius: 8px;
    height: auto;
    background-color: #ffffff;
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 30px;

    .athena-title {
      font-size: 20px;
      color: #2d3748;
      text-align: center;
      font-weight: 700;
    }

    .athena-sub-title {
      text-align: center;
      margin-top: 40px;
      color: #1c3faa;
      font-size: 18px;
      font-weight: 700;
    }

    .prouct-listing-wrapper {
      width: 100%;
      height: auto;
      margin-top: 20px;
      display: inline-block;
      .product-final-block {
        width: 50%;
        height: 100px;
        display: inline-block;
        padding: 15px;
        .product-block {
          display: flex;
          width: 100%;
          height: 100%;

          .circle-block {
            height: 100%;
            display: flex;
            align-items: center;
            .circle-to-select {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #edf2f6;
              cursor: pointer;
              .circle-child-bg {
                width: 35px;
                height: 35px;
                border-radius: 50%;
              }
              .circle-child-bg.active {
                background-color: #1c3faa;
              }
            }
          }

          .product-name {
            height: 100%;
            padding-left: 15px;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .next-hit-wrapper {
    margin-top: 45px;
    width: 100%;
    display: flex;
    justify-content: center;
    .next-page {
      background-color: #1f40aa;
      padding: 7px 13px 7px 13px;
      font-size: 13px;
      color: #ffffff;
      font-weight: 400;
      cursor: pointer;
      transition: all 0.3s linear;
      border-radius: 5px;
      &:hover {
        background-color: #152d7bbf;
      }
    }
  }
}
