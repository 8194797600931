.home-container {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #1c3faa;
  min-height: 100vh;
  padding: 15px 15px 15px 0;
  transition: all 0.3s linear;
  .sidebar-wrapper {
    position: fixed;
    .sidebar-fixed {
      position: fixed;
    }

    .sidebar-fixed.open {
      width: 270px;
    }

    .sidebar-fixed.close {
      width: 100px;
    }
  }

  .sidebar-fixed.close {
    .menu-items {
      display: flex;
      justify-content: center;
    }
    .item-name {
      display: none !important;
    }
  }

  .page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: #f1f3f9;
    border-radius: 8px;
    width: inherit;
  }

  .page-wrapper.open {
    margin-left: 270px;
    width: -webkit-fill-available;
  }

  .page-wrapper.close {
    margin-left: 100px;
  }

  .content-area {
    width: 100%;
    padding: 0 15px 15px 15px;
  }
}

.shiftLeft {
  display: flex;
  height: 100%;
  width: 8%;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
}

.shiftRight {
  display: flex;
  height: 100%;
  width: 8%;
  align-items: center;
  justify-content: center;
  margin-left: -240px;
}

@media only screen and (max-width: 720px) {
  .home-container{
    padding: 0 !important;
  
    .page-wrapper{
      margin: 0 !important;
      background-color: #1c3faa;
    }
  }
  
}

.name-inititiol {
  width: 35px;
  height: 35px;
  background-color: #e16666;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  border-radius: 50%;
  text-align: center;
  line-height: 1;
  color: #ffffff;
  margin-right: 15px;
  text-transform: uppercase;
}

.name-inititiol-recent {
  width: 35px;
  height: 35px;
  background-color: #e16666;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 1;
  color: #ffffff;
  margin-right: 5;
  text-transform: uppercase
}
