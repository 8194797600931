@import "variables.scss";


.authtncheighlight{
  color: #1c3faa !important;
}

.signupContainer {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #ffffff;
  &:before {
    background-image: url(../assets/image/bg-login-page.svg);
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: "";
    height: 100%;
    left: 0;
    margin-left: -48%;
    position: absolute;
    top: 0;
    width: 100%;
  }
  display: flex;
  .commonSection {
    width: 50%;
    height: 100%;
    z-index: 1;
    .athenaLogoContainer {
      width: 100%;
      height: auto;
      display: flex;
      .athenaLogo {
        width: 302px;
        height: 94px;
        margin-left: 76px;
        margin-top: 24px;
      }
    }
    .illustration {
      width: 100%;
      height: auto;
      display: flex;
      .illustrationImg {
        margin-left: 146px;
        height: 280px;
        width: 403px;
        margin-top: 162px;
      }
    }

    .signinText {
      margin-top: 44px;
      height: auto;
      width: 403px;
      font-size: 39px;
      font-weight: bold;
      line-height: 1.26;
      color: #fff;
      margin-left: 146px;
      text-align: left;
    }

    .helpingText {
      width: 100%;
      margin-top: 13px;
      font-size: 28px;
      margin-left: 146px;
      color: #fff;
      text-align: left;
      opacity: 0.8;
      display: flex;
    }
  }
  .rightSection {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    .signInWrapper {
      padding-left: 80px;
      .authLabel {
        font-size: 32px;
        color: #2e3949;
        text-align: left;
        font-weight: bold;
        line-height: 1.53;
      }
      .authInput {
        display: block;
        margin-top: 17px;
        width: 375px;
        height: 55px;
      }
      .authRememberWr {
        width: 375px;
        .authRemember {
          font-size: 15px;
          color: #4b5768;
        }
      }
      .authButtons {
        margin-top: 36px;
        display: flex;
        .btn-auth {
          width: 137px;
          height: 48px;
          box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.03);
          background-color: #174ea2;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          color: #fff;
          font-weight: lighter;
          border-radius: 7px;
          cursor: pointer;
        }
        .btn-auth-alt {
          margin-left: 17px;
          width: 137px;
          height: 48px;
          box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.03);
          background-color: rgba(0, 0, 0, 0.03);
          display: flex;
          justify-content: center;
          border: solid 1px #e2e8f0;
          align-items: center;
          font-size: 15px;
          cursor: pointer;
          color: #4b5768;
          font-weight: lighter;
          border-radius: 7px;
        }
      }

      .authtnc {
        width: 255px;
        height: auto;
        margin-top: 55px;
        color: #4b5768;
        font-size: 15px;
        line-height: 1.47;
        text-align: left;
        .authtncheighlight {
          font-size: 15px;
          color: #2141a8;
          line-height: 22px;
        }
      }

      .securePwd {
        margin-top: 30px;
        font-size: 15px;
        color: #748196;
        text-align: left;
      }
      .authagree {
        margin-top: 20px;
        .authAgreeText {
          font-size: 15px;
          color: #4c5869;
          text-align: left;
          .authtncheighlight {
            color: #2141a8;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .signupContainer {
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #ffffff;
    &:before {
      background-image: url(../assets/image/bg-login-page.svg);
      background-position: 100%;
      background-repeat: no-repeat;
      background-size: auto 100%;
      content: "";
      height: 100%;
      left: 0;
      margin-left: -48%;
      position: absolute;
      top: 0;
      width: 100%;
    }
    display: flex;
    .commonSection {
      width: 50%;
      height: 100%;
      z-index: 1;
      .athenaLogoContainer {
        width: 100%;
        height: auto;
        display: flex;
        .athenaLogo {
          width: 200px;
          height: 60px;
          margin-left: 70px;
          margin-top: 20px;
        }
      }
      .illustration {
        width: 100%;
        height: auto;
        display: flex;
        .illustrationImg {
          margin-top: 80px;
          margin-left: 80px;
          height: 220px;
          width: 270px;
        }
      }

      .signinText {
        height: auto;
        margin-top: 0px;
        width: 300px;
        font-size: 26px;
        font-weight: bold;
        line-height: 1.26;
        color: #fff;
        margin-left: 80px;
        text-align: left;
      }

      .helpingText {
        width: 100%;
        margin-top: 8px;
        font-size: 21px;
        margin-left: 80px;
        color: #fff;
        text-align: left;
        opacity: 0.8;
        display: flex;
      }
    }
    .rightSection {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      .signInWrapper {
        padding-left: 80px;
        .authLabel {
          font-size: 20px;
          color: #2e3949;
          text-align: left;
          font-weight: bold;
          line-height: 1.53;
        }
        .authInput {
          display: block;
          margin-top: 17px;
          width: 300px;
          height: 55px;
        }
        .authRememberWr {
          width: 300px;
          margin-top: 13px;
          .authRemember {
            font-size: 12px;
            color: #4b5768;
          }
        }
        .authButtons {
          margin-top: 20px;
          display: flex;
          .btn-auth {
            width: 117px;
            height: 35px;
            box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.03);
            background-color: #174ea2;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            color: #fff;
            font-weight: lighter;
            border-radius: 7px;
            cursor: pointer;
          }
          .btn-auth-alt {
            margin-left: 17px;
            width: 117px;
            height: 35px;
            box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.03);
            background-color: rgba(0, 0, 0, 0.03);
            display: flex;
            justify-content: center;
            border: solid 1px #e2e8f0;
            align-items: center;
            font-size: 13px;
            cursor: pointer;
            color: #4b5768;
            font-weight: lighter;
            border-radius: 7px;
          }
        }

        .authtnc {
          width: 255px;
          height: auto;
          margin-top: 16px;
          color: #4b5768;
          font-size: 12px;
          line-height: 1.47;
          text-align: left;
          .authtncheighlight {
            font-size: 12px;
            color: #2141a8;
            line-height: 22px;
          }
        }

        .securePwd {
          margin-top: 30px;
          font-size: 15px;
          color: #748196;
          text-align: left;
        }
        .authagree {
          margin-top: 20px;

          .authAgreeText {
            font-size: 15px;
            color: #4c5869;
            text-align: left;
            .authtncheighlight {
              color: #2141a8;
            }
          }
        }
      }
    }
  }
  .authInputPadding {
    input {
      padding: 13px 13px 13px 16px;
      font-size: 12px;
      color: #a0afbf;
    }
  }
}

.authInputPadding {
  input {
    padding: 16px 16px 15px 19px;
    font-size: 15px;
    color: #a0afbf;
  }
}

.authInput {
  display: block;
  margin-top: 17px;
  width: 350px;
  height: auto;
}

.required-star {
  line-height: 10px;
  display: flex;
  flex-direction: row-reverse;
  svg {
    width: 10px;
    height: 10px;
    fill: #d75162;
  }
}

@media only screen and (max-width: 500px) {
  .illustration {
    display: none;
  }
}

@media only screen and (max-width: $medium) {
  .authInput {
    width: 100%;
  }
}

@media only screen and (max-width: 1280px) {
  .authInput {
    width: 100%;
  }
}

.authagree {
  margin-top: 10px;
}

.auth-agree-checkbox {
  padding: 0 5px 0 0 !important;
}
