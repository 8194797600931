.data-form-container {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(44, 40, 40, 0.11);
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  margin-top: 21px;
  margin-bottom: 40px;
  width: 100%;
}

.cips-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .form-chips {
    margin: 7px 20px 0 0 !important;
    cursor: pointer !important;
  }
}

.current-form-name {
  width: 100%;
  font-size: 23px;
  color: #2e3949;
  line-height: 1.22;
  font-weight: 500;
  padding: 18px 0 18px 0;
  border-bottom: solid 1px #c3c3c3;
  text-align: center;
}

.form-area-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding: 15px 5% 15px 5%;
  .form-area-wrapper {
    width: 50%;
  }
}

.form-box-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 10px;

  .date-component {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .radio-component {
    display: flex;
    height: 54px;
    border-radius: 4px;
    margin-bottom: -9px;
    align-items: center;
    justify-content: center;
  }

  .audit-report-wrapper {
    border: 1px solid black;
    height: 57px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    position: relative !important;
    .audit-report-name {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      height: 38px;
      border-radius: 6px;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      .upload-audit-icon {
        position: absolute;
        right: 30px;
        width: 18px;
        height: 22px;
        cursor: pointer;
      }
      .upload-audit-placeholder {
        font: inherit;
        color: #a9a9a9b8;
        width: 100%;
        border: 0;
        height: 1.1876em;
        margin: 0;
        display: block;
        padding: 6px 0 7px;
        min-width: 0;
        font-size: 16px;
        background: none;
        box-sizing: content-box;
        animation-name: mui-auto-fill-cancel;
        letter-spacing: inherit;
        animation-duration: 10ms;
        -webkit-tap-highlight-color: transparent;
        padding-left: 10px;
      }
    }
  }

  .multiple-social-media {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 80px;
    align-items: center;
    label {
      font-size: 13px;
    }
  }
}

.add-another-text {
  text-align: right;
  color: #3f51b5;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  padding: 0 10px 0 10px;
}

.data-from-terms {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  padding: 0 20px 0 20px;
  margin-bottom: 15px;
}

.lower-size-placeholder {
  label {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 1100px) {
  .form-area-container {
    padding: 15px 3% 15px 3%;
    .MuiInputLabel-formControl {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .form-area-container {
    padding: 15px 1% 15px 1%;
    display: block;
    .MuiInputLabel-formControl {
      font-size: 15px;
    }
    .form-area-wrapper {
      width: 100%;
    }
  }
}
