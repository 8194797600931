.subscription-wrapper {
  width: 100%;
  height: auto;

  .next-hit-wrapper {
    margin-top: 45px;
    width: 100%;
    display: flex;
    justify-content: center;
    .next-page {
      background-color: #1f40aa;
      padding: 7px 13px 7px 13px;
      font-size: 13px;
      color: #ffffff;
      font-weight: 400;
      cursor: pointer;
      transition: all 0.3s linear;
      border-radius: 5px;
      &:hover {
        background-color: #152d7bbf;
      }
    }
  }

  .subscription-loader{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .subs-block{
    background-color: #ffffff;
    border-radius: 8px;
  }

  .subscription-card {
    width: 100%;
    margin-top: 20px;
    .sub-title {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #2e3949;
      font-weight: 400;
      border-bottom: 1px solid #c3c3c3;
    }
  }

  .subs-item-block {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    overflow-x: auto;
    .subs-item.active{
      border: 2px solid #000000;
      background-color: #f1f5f8;
    }
    .subs-item {
      width: 240px;
      height: auto;
      margin: 0 10px 0 10px;
      border: 1px solid #1c3faa;
      border-radius: 22px;
      padding: 20px;
      cursor: pointer;
      transition: all .3s linear;
      &:hover{
        background-color: #f1f5f8;
      }

      .image-section {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 45px;
          height: auto;
        }
      }
    }
    
    .subs-title {
      font-size: 16px;
      color: #391400;
      font-weight: 400;
    }
    .subs-description {
      margin-top: 15px;
      font-size: 13px;
      color: rgba(57, 20, 0, 0.64);
      line-height: 1.5;
    }
    .subs-action{
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .subs-download {
        background-color: #1f40aa;
        padding: 7px 13px 7px 13px;
        font-size: 13px;
        color: #ffffff;
        font-weight: 400;
        cursor: pointer;
        transition: all 0.3s linear;
        border-radius: 5px;
        &:hover {
          background-color: #152d7bbf;
        }
      }
    }
  }

}
