.cart-wrapper {
  width: 100%;
  box-shadow: 0 3px 6px 0 rgb(44 40 40 / 11%);
  background-color: #ffffff;
  height: auto;
  margin-bottom: 32px;
  padding: 20px;

  .cart-item-wrapper {
    width: 100%;
    .cart-item {
      border-bottom: 1px solid #dbdbdb;
      margin-bottom: 15px;
      width: 70%;
      display: flex;
      padding-bottom: 15px;
      .item-image {
        box-shadow: inset 0px 0px 17px -1px rgb(0 0 0 / 26%);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e3e3e3;
        width: 120px;
        img {
          width: 100%;
          height: auto;
        }
      }

      .item-detail {
        width: stretch;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cart-product-name {
          padding-left: 15px;
          font-size: 23px;
          color: #393e46;
        }
        .remove-button {
          height: 30px;
          width: 135px;
          border-radius: 25px;
          border: solid 1px #707070;
          display: flex;
          font-size: 16px;
          justify-content: center;
          align-items: center;
          color: #000;
          background-color: transparent;
          cursor: pointer;
          transition: all 0.3s linear;
        }
      }
    }
  }

  .cart-empity-content {
    padding: 43px 25px 43px 25px;
    font-size: 23px;
    font-family: Roboto-Regular !important;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .billing-wrapper {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .billing-block {
      width: 350px;
      .summary-title {
        font-size: 23px;
        color: #000000;
        font-weight: bold;
        margin-bottom: 15px;
      }
      .controls {
        display: flex;
        margin-top: 15px;
        justify-content: space-between;
        .button-continue {
          height: 35px;
          border-radius: 25px;
          border: solid 1px #707070;
          justify-content: center;
          align-items: center;
          color: white;
          background-color: transparent;
          padding: 0 2em 0 2em;
          cursor: pointer;
          font-size: 12px;
          white-space: nowrap;
          display: flex;
          align-items: center;
          line-height: 1;
          transition: all 0.3s linear;
        }
        .button-order {
          height: 35px;
          border-radius: 25px;
          border: solid 1px #707070;
          justify-content: center;
          align-items: center;
          color: white;
          background-color: #174ea2;
          display: flex;
          line-height: 1;
          padding: 0 2em 0 2em;
          font-size: 12px;
          cursor: pointer;
          transition: all 0.3s linear;
        }
      }

      .total-price {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        .total-price {
          font-size: 15px;
        }
        .total-amount {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
}

.cartWr {
  .cartWr {
    padding: 1em;
  }
}

.proBoxes {
  display: flex;
  flex-direction: row;
  width: 240px;
  box-shadow: 0 3px 6px 0 rgba(44, 40, 40, 0.11);
  padding: 14px 11px 10px 14px;
  border-radius: 10px;
}

.pageTitle {
  font-family: Roboto-Regular;
  font-size: 25px;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
}

.revWr {
  width: 100%;
  padding: 1em;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .dashboardPaperContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 1200px) {
  .cart-item-wrapper {
    width: 100%;
    .cart-item {
      width: 100% !important;
      display: block !important;
      .item-image {
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;
        img{
          max-width: 170px;
          height: auto;
        }
      }
      .item-detail {
        display: block !important;
        width: 100% !important;
        margin-top: 15px !important;
        .cart-product-name {
          width: 100% !important;
          text-align: left !important;
          display: block !important;
          padding-left: 0 !important;
        }
        .remove-button {
          margin-top: 15px !important;
        }
      }
    }
  }
  .billing-wrapper{
    .controls{
      display: block !important;
      .button-order{
        margin-top: 15px !important;
      }
    }
  }
}
