.chat-wrapper {
  width: 100%;
  height: auto;
  .page-title {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 15px;
    color: #2d3748;
    font-weight: 500;
  }
}

.chat-block {
  width: 100%;
  background-color: #f1f5f8;
  display: flex;
  position: relative;
  overflow: hidden;
  .contact-toggle {
    position: absolute;
    z-index: 15;
    background-color: antiquewhite;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: 20px;
    left: -7px;
    transition: all 0.3s linear;
    &:hover {
      background-color: #163183db !important;
      svg {
        fill: #fff;
      }
    }
  }

  .contact-toggle.active {
    svg {
      transition: all 0.2s linear;
      transform: rotate(0deg);
    }
  }

  .contact-toggle.inactive {
    svg {
      transition: all 0.2s linear;
      transform: rotate(180deg);
    }
  }

  .contact-list {
    width: 25%;
    min-width: 25%;
    height: 670px;
    overflow-y: auto;
    padding-right: 0.5em;

    .contact-loading {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 150px;
    }

    .contact-item.active {
      background-color: #e1e0e0ab;
    }
    .contact-item {
      background-color: #ffffff;
      border-radius: 8px;
      padding: 5px;
      width: auto;
      height: 70px;
      display: flex;
      align-items: center;
      box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.03);
      margin-bottom: 10px;
      transition: all 0.3s linear;
      cursor: pointer;
      &:hover {
        background-color: #e1e0e0ab;
      }

      .contact-image {
        overflow: hidden;
        margin-left: 5px;
        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
        }
      }

      .detail-name {
        // width: inherit;
        height: 70px;
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #2c3847;
        // text-align: start;
        padding-left: 10px;
        .analyst-tag {
          font-size: 9px;
          color: #b18e4e;
        }

        .unread-count {
          width: 17px;
          height: 17px;
          display: flex;
          justify-content: center;
          font-size: 10px;
          border-radius: 50%;
          background-color: green;
          color: white;
          margin-bottom: 5px;
          margin-left: 3px;
          line-height: 17px;
          text-align: center;
          align-items: center;
          word-spacing: 0;
          padding: 0;
        }
      }
    }
  }

  .chat-section {
    width: 75%;
    padding: 0 10px 0 10px;
    position: relative;

    .user-not-selected {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #000000;
      background-color: #ffffff;
      border-radius: 8px;
    }

    .chat-header-wrapper {
      width: 100%;
      height: 70px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom: 1px solid #edf2f6;
      margin-top: 0;
      .sender-image {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        padding: 5px;
        margin-left: 15px;
        overflow: hidden;
      }
      .sender-name {
        font-size: 14px;
        color: #2d3848;
        height: 70px;
        display: flex;
        align-items: center;
        padding-left: 10px;
      }
    }

    .chat-view-block {
      width: 100%;
      height: 500px;
      background-color: #ffffff;
      padding: 10px;
      overflow-y: auto;

      .incoming-message-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        text-align: left;

        .chat-file-date {
          word-break: break-all;
        }

        .chat-file {
          background-color: #c4c4c491;
          border-radius: 8px;
          padding: 8px;
          margin-top: 8px;
          width: 180px;
          cursor: pointer;
          img {
            height: 50px;
          }
        }

        .incoming-message {
          overflow: hidden;
          padding: 0;
          margin: 5px 0 5px 0;
          width: auto;
          max-width: 65%;
          height: auto;
          display: block;
          background-color: #edf2f6;
          padding: 10px;
          border-radius: 8px;
          border-bottom-left-radius: 0;

          .message-time {
            font-size: 12px;
            line-height: 1.5;
            color: #718196;
            font-weight: 400;
            padding: 0;
          }

          .actual-message {
            line-height: 1.1;
            font-size: 14px;
            min-height: 1em;
            height: auto;
            max-height: auto;
            color: #000000 !important;
            min-width: 7em;
            width: auto;
            max-width: 100%;
            border-radius: 0.7em;
            word-wrap: break-word;
            border-bottom-right-radius: 0;
          }
        }
      }

      .outgoing-message-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        text-align: right;

        .chat-file {
          background-color: #c4c4c491;
          border-radius: 8px;
          padding: 8px;
          margin-top: 8px;
          width: 180px;
          cursor: pointer;
          img {
            height: 50px;
          }
        }

        .chat-file-date {
          word-break: break-all;
        }

        .incoming-message {
          overflow: hidden;
          padding: 0;
          margin: 5px 0 5px 0;
          width: auto;
          max-width: 65%;
          height: auto;
          display: block;
          background-color: #edf2f6;
          padding: 10px;
          background-color: #1c3faa;
          right: 0;
          border-radius: 8px;
          border-bottom-right-radius: 0;
          .message-time {
            font-size: 12px;
            line-height: 1.5;
            color: #c7d4fc;
            font-weight: 400;
            padding: 0;
          }

          .actual-message {
            line-height: 1.1;
            font-size: 14px;
            min-height: 1em;
            height: auto;
            max-height: auto;
            color: #ffffff !important;
            min-width: 7em;
            width: auto;
            max-width: 100%;
            border-radius: 0.7em;
            word-wrap: break-word;
            border-bottom-right-radius: 0;
          }
        }
      }
    }

    .chat-writing-section {
      box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.03);
      height: 100px;
      width: 100%;
      border-top: 1px solid #c4c4c4;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: #ffffff;
      padding: 15px;
      display: flex;
      align-items: center;
      textarea {
        outline: none;
        box-shadow: none;
        // border:1px solid black;
        background-color: inherit;
        color: #000000;
        resize: none;
        width: inherit;
        height: 70px;
      }

      .chat-controls {
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        .file-details {
          width: 170px;
          height: auto;
          background: aliceblue;
          border-radius: 8px;
          margin-bottom: 300px;
          margin-right: 75px;
          position: absolute;
          padding: 10px;
          .file-image {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: auto;
              height: 80px;
            }
          }
          .file-details {
            width: 100px;
            height: 150px;
          }
          .file-name {
            color: #000000;
            font-size: 12px;
          }
          .file-size {
            color: #000000;
            font-size: 12px;
          }
          .uploading-action {
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }

        .chat-attach {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.3s linear;
          background-color: antiquewhite;
          img {
            width: 16px;
            height: auto;
            cursor: pointer;
          }
        }

        .chat-hit {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background-color: #1c3faa;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.3s linear;
          img {
            width: 16px;
            height: auto;
          }
          &:hover {
            background-color: #163183db;
          }
        }
      }
    }
  }
}

/*scrollbar*/
/* total width */
.scrollbar::-webkit-scrollbar {
  width: 0.3em;
  background-color: inherit;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  border-radius: 0.5em !important;
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #424242;
  border-radius: 0.3em;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: white;
}

.scrollbar::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.scrollbar::-webkit-scrollbar-corner {
  background-color: transparent;
}
/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* div box */
.scrollbar {
  overflow-y: scroll;
}
/*scrollbar*/

.name-inititiol-contact {
  width: 30px;
  height: 30px;
  background-color: #e16666;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  border-radius: 50%;
  text-align: center;
  line-height: 1;
  color: #ffffff;
  text-transform: uppercase;
}

.contact-toggle {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .contact-toggle {
    display: flex;
  }

  .chat-wrapper {
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 8px;
    .page-title {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 20px;
      border-bottom: solid 1px #edf2f6;
      margin-bottom: 0;
    }

    .chat-block {
      padding: 5px;
      position: relative !important;
      .contact-list.inactive {
        z-index: 0 !important;
        margin-left: -160px;
      }
      .contact-list {
        width: 150px;
        min-width: 150px;
        height: 670px;
        overflow-y: auto;
        padding: 5px;
        z-index: 5;
        position: absolute;
        background: rgb(133, 189, 189);
        background-color: #ffff;
        box-shadow: 3px 3px 6px -2px #00000087;
        .name-inititiol-contact {
          display: none;
        }
        .contact-item {
          background-color: #f1f3f9;
        }
      }
      .chat-section {
        width: 100%;
        padding: 0 !important;
      }
    }
  }
}
