.progress-wrapper {
  height: 3px;
  width: 112px;
  background-color: #e0e0de;
  border-radius: 50px;
  margin: 5px;
  .progress-wrapper-child {
    height: 100%;
    border-radius: inherit;
    text-align: right;
    .progress-wrapper-text{
      padding: 5px;
      color: black;
      font-weight: bold;
    }
  }
}
