.containerAlt {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  background-color: #383838;
}

.textContainerSignUp {
  display: flex;
  width: 25%;
  font-family: Roboto-Regular;
  color: #ffffff;
  justify-content: center;
}

.formContainer {
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
}

.tabContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3em;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}

.tabBlockSignUp {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: Roboto-Regular;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.MuiPickersToolbar-toolbar {
  background-color: #ffffff;
}

.tabBlockSignIn {
  display: flex;
  width: 50%;
  height: 100%;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  font-family: Roboto-Regular;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.formInputContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 2%;
  align-items: center;
  justify-content: flex-start;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  height: 7%;
  width: 80%;
  margin: 2%;
  font-weight: bold;
  border-radius: 2px;
  border: solid 1px #020202;
  background-color: #ffffff;
}

.SignUpButtonContainer {
  display: flex;
  flex-direction: row;
  height: 7%;
  width: 80%;
  margin: 2%;
  font-weight: bold;
}

.inputStyle {
  width: 100%;
  border: none;
  margin-left: 2%;
  outline: none;
  font-family: Roboto-Regular;
  font-size: 15px;
  text-align: left;
  color: #989fa4;
}

.formFooter {
  display: flex;
  flex-direction: column;
  height: 15%;
  width: 80%;
  margin: 2%;
}

::placeholder {
  color: gray;
  opacity: 1;
}

:-ms-input-placeholder {
  color: gray;
}

::-ms-input-placeholder {
  color: gray;
}

.newContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  margin: 0px;
  padding: 0px;
  background-color:#2b51b4;
}

.textContainerSignIn {
  display: "flex";
  width: 25%;
  font-family: Roboto-Regular;
  text-align: center;
}

.SignInFormContainer {
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.SignInTabContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 15%;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}

.SignInTabBlock {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: Roboto-Regular;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.SignUpTabBlock {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: gray;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.SignInFormInputContainer {
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 100%;
  margin-top: 5%;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
}

.SignInInputContainer {
  display: flex;
  flex-direction: row;
  width: 80%;
  font-weight: bold;
}

.loginButtonContainer {
  display: flex;
  flex-direction: row;
  height: 15%;
  width: 80%;
  margin: 2%;
  font-weight: bold;
}

.ForgotPasswordTextContainer {
  display: flex;
  flex-direction: row;
  height: 5%;
  width: 80%;
  margin: 2%;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
  font-family: Roboto-Regular;
  font-size: 18px;
  text-align: center;
  color: #000000;
}

@media only screen and (max-width: 480px) {
  .formContainer {
    width: 80%;
  }
  .textContainerSignUp {
    width: 80%;
  }
  .SignInFormContainer {
    width: 80%;
  }
  .textContainerSignIn {
    width: 80%;
  }
}

@media only screen and (max-width: 800px) and (min-width: 481px) {
  .formContainer {
    width: 50%;
  }
  .textContainerSignUp {
    width: 80%;
  }
  .SignInFormContainer {
    width: 80%;
  }
  .textContainerSignIn {
    width: 80%;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 801px) {
  .formContainer {
    width: 50%;
  }
  .textContainerSignUp {
    width: 80%;
  }
  .SignInFormContainer {
    width: 80%;
  }
  .textContainerSignIn {
    width: 80%;
  }
}
