
.pagination-wrapper {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  height: 30px;
  .pagination-left-section {
    width: auto;
    height: 100%;
    display: flex;
    .item-block {
      width: 30px;
      height: 30px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      margin-left: 15px;
      align-items: center;
      transition: all 0.3s linear;
      cursor: pointer;
      &:hover {
        background-color: #c7c6c6;
      }
      img {
        width: 15px;
        height: auto;
      }
    }
    .item-block.active {
      background-color: #ffffff;
    }
  }

  .pagination-right-section {
    .item-block {
      .pagination-size-select {
        padding: 6px;
        border-radius: 8px;
      }
    }
  }
}
