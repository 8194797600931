.landingPageContainer {
  display: flex;
  flex-direction: column;
  height: "100%";
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  overflow-x: hidden;
}

.landingPageImageContainer {
  position: "relative";
  height: 55%;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.landingPageLeftContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 40%;
  align-items: center;
  justify-content: center;
}

.landingPageMiddleContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 20%;
  align-items: flex-end;
  justify-content: center;

  padding-bottom: 2%;
}

.landingPageRightContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 40%;
  align-items: center;
  justify-content: center;
}

.landingPageUserInfoContainer {
  display: flex;
  flex-direction: column;
  height: 45%;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  text-align: justify;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  height: 25%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.lineContainer {
  width: 10%;
  height: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fb6340;
}

.infoContainer {
  display: flex;
  height: 50%;
  width: 95%;
  margin-top: 2%;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  height: 20%;
  width: 100%;
}

.emptySpace {
  display: flex;
  flex-direction: row;
  height: 2%;
  width: 100%;
}

.landingPageButtonContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-left: 5%;
}
