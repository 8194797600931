@font-face {
  font-family: Roboto-Light;
  src: url(../font/Roboto-Light.ttf);
}
@font-face {
  font-family: Roboto-Regular;
  src: url(../font/Roboto-Regular.ttf);
}
@font-face {
  font-family: Roboto-Medium;
  src: url(../font/Roboto-Medium.ttf);
}
@font-face {
  font-family: Roboto-SemiBold;
  src: url(../font/Roboto-Medium.ttf);
}
@font-face {
  font-family: Roboto-Bold;
  src: url(../font/Roboto-Bold.ttf);
}
@font-face {
  font-family: Roboto-Black;
  src: url(../font/Roboto-Black.ttf);
}
@font-face {
  font-family: Roboto-Italic;
  src: url(../font/Roboto-Italic.ttf);
}
