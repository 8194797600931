.add-user-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  background-color: transparent;
  padding: 10px;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  border-radius: 8px;
  border-radius: 2px solid #bfbaba;
  align-items: center;

  .feedback-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .feedback-form {
      background-color: #ffffff;
      border-radius: 8px;
      overflow: scroll;
      position: relative;
      width: 500px;
      height: 400px;
      overflow: hidden;

      .feedback-header {
        font-size: 15px;
        color: #2d3748;
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: center;
        padding-top: 25px;
        align-items: center;
      }

      .feedback-text {
        margin-top: 30px;
        font-size: 14px;
        color: #2d3748;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .feedback-text-alt {
        margin-top: 5px;
        font-size: 14px;
        color: #2d3748;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .feedback-text-three {
        margin-top: 20px;
        font-size: 13px;
        color: #2d3748;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .feedback {
        margin-top: 15px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;

        .circle {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          cursor: pointer;
          margin: 0 10px 0 10px;
          justify-content: center;
          img {
            width: 30px;
            height: auto;
          }
        }

        .circle.active {
          border: 2px solid #17d0e3;
          box-shadow: 0 0 5px 1px #00000073;
        }

        .green {
          background-color: #00bc78;
        }

        .yellow {
          background-color: #fbcb47;
        }

        .red {
          background-color: #e97151;
        }
      }

      .feedback-text-four {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        color: #2d3748;
      }

      .feedback-textarea {
        border-radius: 8px;
        width: 94%;
        border: 1px solid #e2e8f0;
        font-size: 15px;
        color: #a8b6c4;
        margin: 10px 3% 0 3%;
        padding: 10px;
        resize: none;
      }

      .feedback-submition {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        .hit-button {
          padding: 5px 12px;
          background-color: #2141a8;
          font-size: 13px;
          border-radius: 5px;
          color: #fff;
          text-align: center;
          height: 32px;
          align-items: center;
          display: flex;
          width: 100px;
          transition: all 0.3s linear;
          cursor: pointer;
          justify-content: center;
          &:hover {
            background-color: #1a3694;
          }
        }
      }
    }
  }
}

.feedback-loader {
  position: absolute;
  background-color: #ffffffa3;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
