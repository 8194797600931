

.product-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  .product-first-item{
    margin-top: 79px;
    display: flex;
  }
  .product-second-item{
    margin-top: 79px;
    margin-left: 140px;
    display: flex;
  }
}

@media only screen and (max-width: 820px) {
  .product-wrapper {
    display: block;
    height: 100%;
    justify-content: center;
    .product-first-item{
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
    .product-second-item{
      margin-top: 15px;
      margin-left: 0;
      display: flex;
      justify-content: center;
    }
  }
}
