.header-wrapper {
  display: none !important;
}
.header-wrapper,
.appbar-wrapper-section {
  height: 50px;
  display: block;
  align-items: center;
  width: 100%;
  position: relative;
  border-bottom: solid 1px #dee7ef;
  .header-toolbar {
    padding: 0 !important;
    display: block;
    align-items: initial !important;
  }

  .header-mobile-section {
    display: none;
    width: 100%;
    overflow: hidden;
    height: 70px;
    .static-wrapper {
      height: 70px;
      min-height: 70px;
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 2px solid #dee7ef;
      .company-logo {
        padding-left: 20px;
        img {
          width: 140px;
          height: 40px;
        }
      }
      .MuiIconButton-root {
        display: flex;
        right: 15px;
        position: absolute;
      }
      .menu-icon {
        color: white;
      }
    }

    .menu-wrapper {
      display: block;
      width: 100%;
      height: auto;
      padding: 20px 5px 5px 20px;
      .menu-item {
        display: flex;
        height: 50px;
        width: 100%;
        .menu-icon {
          width: 15%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          img {
            width: 20px;
            height: 20px;
          }
        }
        .menu-text {
          width: 85%;
          display: flex;
          align-items: center;
          font-size: 15px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
        }
      }
    }
  }

  .breadcrumb-section {
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;

    .breadcrumb-icon {
      width: auto;
      padding: 0 1px 0 1px;
      img {
        width: 17px;
        height: auto;
      }
    }
    .breadcrumb-text {
      font-size: 13px;
      line-height: 1;
      color: #2f3747;
      margin: 0;
      &:hover {
        cursor: pointer;
      }
    }

    .breadcrumb-text.active {
      color: #1d40aa !important;
    }
  }

  .header-tools {
    width: auto;
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    top: 0;
    right: 0;

    .header-item {
      width: 30px;
      height: 20px;
      margin: 0 10px 0 10px;
      &:hover {
        cursor: pointer;
      }
      position: relative;
      .cart-items-number {
        position: absolute;
        background-color: #bb3737;
        right: -3px;
        top: -7px;
        width: 15px;
        height: 15px;
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 1;
        font-size: 9px;
        color: white;
      }
    }

    .header-item-profile {
      margin: 0 10px 0 10px;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      overflow: hidden;
      &:hover {
        cursor: pointer;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .header-desktop-section {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    height: 60px;
    align-items: center;
  }
}

@media only screen and (max-width: 720px) {
  .breadcrumb-section {
    display: none !important;
  }
  .header-wrapper {
    background-color: #1c3faa;
    border-bottom: 0 !important;
    padding: 0 !important;
    display: flex !important;
  }

  .appbar-wrapper-section {
    height: auto !important;
  }

  .sidebar-wrapper {
    display: none;
  }
  .homeRightScreenContainer {
    width: 100% !important;
  }
  .header-wrapper.active {
    height: auto;
  }
  .header-wrapper {
    height: 80px;
    .header-mobile-section.active {
      height: auto;
    }
    .header-mobile-section {
      display: block;
    }
  }

  .header-desktop-section {
    display: flex;
    position: relative;
    background-color: #f1f5f8;
    height: 70px;
    border-top-left-radius: 10px;
    padding: 15px;
    border-top-right-radius: 10px;
    margin-top: 15px;
  }
}
