.add-user-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  background-color: transparent;
  padding: 10px;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  border-radius: 8px;
  border-radius: 2px solid #bfbaba;
  align-items: center;

  .feedback-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .feedback-form {
      background-color: #ffffff;
      border-radius: 8px;
      overflow: scroll;
      position: relative;
      width: 500px;
      min-height: 450px;
      height: auto;
      overflow: hidden;
      padding: 25px 15px 25px 15px;

      .demo-loader-section{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        background-color: #ffffff7b;
      }

      .title-picture{
        width: 100%;
        display: flex;
        justify-content: center;
        img{
          width: 120px;
          height: 120px;
          border-radius: 50%;
          overflow: hidden;
        }
      }

      .title {
        color: #2e3949;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 10px;
      }

      .form-area {
        height: auto;
        .profile-input-box {
          margin-bottom: 20px;
        }
      }

      .profile-form-submit {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
        button {
          background-color: #1f40aa;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          color: #fff;
          font-size: 15px;
          width: 100%;
          height: 40px;
          transition: all 0.3s linear;
          &:hover {
            background-color: #152d7e;
          }
        }
      }

      .feedback-submition {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        .hit-button {
          padding: 5px 12px;
          background-color: #2141a8;
          font-size: 13px;
          border-radius: 5px;
          color: #fff;
          text-align: center;
          height: 32px;
          align-items: center;
          display: flex;
          width: 100px;
          transition: all 0.3s linear;
          cursor: pointer;
          justify-content: center;
          &:hover {
            background-color: #1a3694;
          }
        }
      }

      .close-modal {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
        button {
          background-color: #91374d;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          color: #fff;
          font-size: 15px;
          width: 100%;
          height: 40px;
          transition: all 0.3s linear;
          &:hover {
            background-color: #7e2b40;
          }
        }
      }

    }
  }
}
