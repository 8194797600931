
.pic {
      background-blend-mode: overlay;
}

.text-big  {
    font-size:109px;
    text-align: left;
}


.text-bold {
    text-align: left;
    font-weight: bold;
}
.back-btn {
  height: 49px;
  border-radius: 5px;
  border: solid 1px #fff;
  padding: 0px 20px 0px 20px;

}