.order-verify {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .order-wrapper {
    height: auto;
    width: fit-content;
    .loader-section {
      display: flex;
      justify-content: center;
    }
    .verify-text-error {
      color: rgb(158, 45, 45);
    }
  }

  .loader-small {
    border: 5px solid #e2e2e2;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    border-top: 5px solid #000000;
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }

  .verify-text {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
    margin: 10px 0 10px 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
