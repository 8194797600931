html {
  background-color: #f1f3f9;
}
body{
  padding: 0 !important;
}
.theme-bgcolor {
  background-color: #174ea2 !important;
}

.bgtheme {
  background-color: #f2f2f2;
}
.bgwhite {
  background-color: #fff;
}
.bglight {
  background-color: #f7f7f7;
}
.bglightdark {
  background-color: #e3e3e3;
}
.reviewBg {
  background-color: #fff9f4;
}
.bgblack {
  background-color: #000000;
}

.authactive {
  background-color: #a3aab0;
}
.reviewBgup {
  background-color: #d9d9d9;
}

.p-05 {
  padding: 0.5em;
}
.p-1 {
  padding: 1em;
}
.p-4 {
  padding: 4em;
}
.p-2 {
  padding: 2em;
}
.p-3 {
  padding: 3em;
}
.pt-1 {
  padding-top: 1em;
}
.pt-2 {
  padding-top: 2em;
}
.pt-3 {
  padding-top: 3em;
}
.pl-2 {
  padding-left: 2em;
}
.pl-5 {
  padding-left: 5em;
}
.pl-1 {
  padding-left: 1em;
}
.pl-3 {
  padding-left: 3em;
}
.pt-05 {
  padding-top: 0.5em;
}
.p-05 {
  padding: 0.5em;
}
.pt-8 {
  padding-top: 8em;
}
.pb-2 {
  padding-bottom: 2em;
}
.pb-1 {
  padding-bottom: 1em;
}
.pb-3 {
  padding-bottom: 3em;
}
.pb-4 {
  padding-bottom: 4em;
}
.pb-5 {
  padding-bottom: 5em !important;
}
.ps-1 {
  padding-left: 1em;
}
.ps-2 {
  padding-left: 2em !important;
}
.ps-3 {
  padding-left: 3em;
}
.ps-4 {
  padding-left: 4em;
}
.px-1 {
  padding-left: 1em;
  padding-right: 1em;
}
.px-05 {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.px-2 {
  padding-left: 2em !important;
  padding-right: 2em !important;
}
.px-3 {
  padding-left: 3em;
  padding-right: 3em;
}
.px-5 {
  padding-left: 5em;
  padding-right: 5em;
}
.pr-1 {
  padding-right: 1em;
}
.pr-12px {
  padding-right: 12px;
}
.pr-2 {
  padding-right: 2em;
}
.br10px {
  border-radius: 10px;
}
.br25px {
  border-radius: 25px;
}
.my-1 {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}
.mx-1 {
  margin-left: 1em;
  margin-right: 1em;
}
.mx-2 {
  margin-left: 2em;
  margin-right: 2em;
}
.my-2 {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.my-3 {
  margin-top: 3em !important;
  margin-bottom: 3em !important;
}

.m-0 {
  margin: 0 !important;
}
.mt-5 {
  margin-top: 5em;
}
.mt20px {
  margin-top: 20px;
}
.mt-4 {
  margin-top: 4em;
}
.mt-3 {
  margin-top: 3em;
}
.mt-2 {
  margin-top: 2em;
}
.mt-1 {
  margin-top: 1em;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}
.mb-3 {
  margin-bottom: 3em;
}
.mb-4 {
  margin-bottom: 4em;
}
.ml-5 {
  margin-left: 2rem;
}
.ml-4 {
  margin-left: 4em;
}
.ml-3 {
  margin-left: 3em;
}
.ml-2 {
  margin-left: 2em !important;
}
.ml-1 {
  margin-left: 1em;
}
.oh {
  overflow: hidden;
}
.mr10px {
  margin-right: 10px;
}

.ml-14 {
  margin-left: 15em;
}

@media only screen and (max-width: 1600px) {
  .ml-14 {
    margin-left: 1em;
  }
  .mr-mediaQuery {
    margin-right: 6em;
  }
}
.py-2 {
  padding-top: 2em;
  padding-bottom: 2em;
}

.col-6 {
  width: 42% !important;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.position-absolute {
  position: absolute !important;
}
.position-relative {
  position: relative !important;
}
.top-0 {
  top: 0;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.h-auto {
  height: auto !important;
}
.hcenter {
  display: flex;
  justify-content: center;
}
.vcenter {
  display: flex;
  align-items: center;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}

.vhcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}
.w-80 {
  width: 80%;
}
.w-75 {
  width: 75%;
}
.w-10 {
  width: 20%;
}
.w-5 {
  width: 5%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}
.w-70 {
  width: 70%;
}
.w-30 {
  width: 30%;
}
.w-95 {
  width: 95%;
}
.h100vh {
  height: 100vh;
}
.text-danger {
  color: #ad175b !important;
}
.emailError {
  padding: 10px;
  z-index: inherit;
}
.text-voilet {
  color: #4e3883;
}
.textblack {
  color: #000000;
}
.textSemiH {
  color: #393e46 !important;
}
.textlight {
  color: #707070;
}
.textlightAlt {
  color: #4d4f5c;
}
.text-white {
  color: #ffffff;
}
.label-color {
  color: rgb(152, 159, 164);
}
.light {
  color: #989fa4;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.m-0 {
  margin: 0;
}
.fw-bold {
  font-weight: bold !important;
}
.-mb20px {
  margin-bottom: -20px;
}
.fs17px {
  font-size: 17px;
}
.h-100 {
  height: 100%;
}
.fs18px {
  font-size: 18px;
}
.fs36px {
  font-size: 36px;
}
.fs19px {
  font-size: 19px;
}
.fs20px {
  font-size: 20px;
}
.fs28px {
  font-size: 28px;
}
.fs14px {
  font-size: 14px;
}
.fs15px {
  font-size: 15px;
}
.fs12px {
  font-size: 12px;
}
.fs13px {
  font-size: 13px;
}
.fs18px-wrap {
  font-size: 18px;
  white-space: nowrap;
}

.bg-blue {
  background-color: #1f42ac;
}
.bg-white {
  background-color: #ffffff;
}

.fs7px {
  font-size: 12px !important;
  white-space: nowrap;
}

.fs11px {
  font-size: 11px !important;
}
.fs22px {
  font-size: 22px;
}
.fs23px {
  font-size: 23px;
}
.fs30px {
  font-size: 30px !important;
}
.fs25px {
  font-size: 25px;
}
.fs40px {
  font-size: 25px;
}
.cp {
  cursor: pointer;
}
.h-377px {
  height: 337px !important;
}
.hunder {
  &:hover {
    text-decoration: underline;
  }
}

.tunder {
  text-decoration: underline;
}

.fonttitl {
  font-family: Roboto, sans-serif;
}
.poplight {
  font-family: Roboto-Light;
}
.popregular {
  font-family: Roboto-Regular !important;
}
.popmedium {
  font-family: Roboto-Medium;
}
.popsemibold {
  font-family: Roboto-Bold;
}
.popbold {
  font-family: Roboto-Bold;
}
.stepCont {
  width: 100%;
}

.textLeft {
  text-align: left;
}

.font-poppins {
  font-family: Roboto-Regular !important;
}
.lh18px {
  line-height: 18px;
}
.lh-105 {
  line-height: 1.05;
}
.lh20px {
  line-height: 20px;
}
.timeBorder {
  border-right: 1px solid #716d6b;
}
.d-flex {
  display: flex;
}

.d-flex-noMatch {
  display: flex;
  background-color: #1f42ac;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.fdrow {
  flex-direction: row;
}
.fdcol {
  flex-direction: column;
}
.align-start {
  align-items: flex-start;
}
.mt-75 {
  margin-top: 7.5em !important;
}
.mt-10px {
  margin-top: 10px !important;
}
.mt-neg {
  margin-bottom: -11px;
}
.divBorder {
  border-left: 3.5px solid black;
  margin: 15px 0px 15px 26px;
}
.dividerC {
  border-bottom: 1px solid #dbdbdb;
}
.border-bottom-review {
  border-bottom: 1px solid #716d6b;
}
.border-top-review {
  border-top: 1px solid #716d6b;
}
.border-around {
  border: 1px solid #716d6b;
}
.border-right {
  border-right: 1px solid #716d6b;
}
.borderPrimary {
  border: solid 1px #3b88fc;
}
.borderLight {
  border: 1px solid #d2d2d2;
}
.br8px {
  border-radius: 8px;
}
.br4px {
  border-radius: 4px;
}
.ml10px {
  margin-left: 10px !important;
}
.checkBoxlabel {
  .MuiFormControlLabel-label {
    font-size: 11px !important;
    font-weight: bold !important;
  }
}

.fd-c {
  flex-direction: column;
}

.jc-start {
  justify-content: start;
}
.jc-fs {
  justify-content: flex-start;
}
.jc-sb {
  justify-content: space-between;
}
.jc-end {
  justify-content: flex-end;
}
.input {
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.orederBtn {
  height: 49.4px;
  background-color: #174ea2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
}

.insetBox {
  box-shadow: inset 0px 0px 17px -1px rgba(0, 0, 0, 0.26);
}
.row {
  display: flex;
  flex-wrap: wrap;
}

.btnTrans {
  height: 30px;
  width: 135px;
  border-radius: 25px;
  border: solid 1px #707070;
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  color: #000;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s linear;
  &:hover {
    transition: all 0.3s linear;
    background-color: #bbbbbb;
  }
}

.btnBlue {
  height: 35px;
  border-radius: 25px;
  border: solid 1px #707070;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #174ea2;
  padding: 0 2em 0 2em;
  cursor: pointer;
  transition: all 0.3s linear;
  &:hover {
    transition: all 0.3s linear;
    opacity: 0.8;
  }
}
a {
  text-decoration: none;
  color: inherit;
}

.h54px {
  height: 54px;
}
.h57px {
  height: 57px;
}
.borderBlack {
  border: 1px solid black;
}
.br6px {
  border-radius: 6px;
}
.h38px {
  height: 38px;
}
.h55px {
  height: 55px;
}

.spanImgCenter {
  span {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: auto;
      height: auto;
      min-height: auto;
      max-height: 8em;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.roundedCorner {
  .MuiIconButton-label {
    border-radius: 6px;
    overflow: hidden;
  }
}

.text-primary {
  color: #3f51b5;
}

.btn-primary {
  background-color: #174ea2;
}

.btn-common {
  height: 34px;
  width: 76px;
  border-radius: 7px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.03);
  white-space: nowrap;
  font-size: 11px;
  outline: none;
  border: none;
  font-family: inherit;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  margin-bottom: 11px;
  margin-right: 17px;
  &:hover {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
}

.btn-next-page {
  background-color: #174ea2;
  height: 34px;
  width: 76px;
  border-radius: 20px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.03);
  white-space: nowrap;
  font-size: 11px;
  outline: none;
  border: none;
  font-family: inherit;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  margin: 40px 17px 11px 0;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
}

.btn-review {
  background-color: #174ea2;
  height: 60px;
  width: 177px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.03);
  white-space: nowrap;
  font-size: 20px;
  outline: none;
  border: none;
  font-family: inherit;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  margin: 40px 17px 0 0;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
}

.text-uppercase {
  text-transform: uppercase;
}
.btn-light {
  background-color: #e0e0e0;
}

.text-ultra-light {
  color: #4b5768;
}

.text-password-light {
  color: #748196;
}

.overflow-x-auto {
  overflow-x: auto;
}

.mt-34px {
  margin-top: 34px;
}

.mt-17px {
  margin-top: 17px;
}
.MuiFormHelperText-contained.Mui-required {
  margin: 0px 0 5px 0 !important;
}



.mt--1em {
  margin-top: -1em;
}

.MuiFormHelperText-contained.Mui-error {
  margin: 0 !important;
}

.ml-52px {
  margin-left: 52px;
}

.appbar-button {
  padding: 0 !important;
  margin-right: 34px !important;
}
.mt-32px {
  margin-top: 32px !important;
}

.mt-40px {
  margin-top: 40px;
}

.ml-30px {
  margin-left: 30px;
}

.ml-26px {
  margin-left: 26px;
}

.form-title {
  margin: 28px 0 24px 26px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-10px {
  margin-bottom: 10px;
}

.ml-140px {
  margin-left: 140px;
}

.mt-79px {
  margin-top: 79px;
}

.ml-70px {
  margin-left: 70px !important;
}
.remove-shading {
  &:hover {
    background-color: transparent !important;
  }
}





.upload-picture-container {
  width: 100%;
  display: flex;
  .avatar-image-container {
    width: 161px;
    height: 98px;
    display: flex;
    width: "100%";
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: solid 1px #010101;
    background-color: #fff;
    .uploaded-image {
      width: auto;
      height: auto;
      max-width: 161px;
      max-height: 98px;
    }
  }
  .button-wrapper {
    position: relative;
  }
  .upload-picture-button {
    width: 120px;
    height: 23px;
    background-color: #174ea2;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    margin-left: 18px;
    position: absolute;
    bottom: 0;
  }
}

.picture-attached {
  width: 100%;
  font-size: 15px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007cf8;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 4px;
}


.text-404 {
  font-size: 104px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  height: 125px;
  line-height: 0.42;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  display: flex;
  align-items: center;
}

.text-oops {
  height: 38px;
  margin-top: 7px;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  display: flex;
  align-items: center;
}

.text-oops-below {
  height: 23px;
  margin-top: 17px;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.32;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  display: flex;
  align-items: center;
}

.back-to-home {
  width: 131px;
  height: 49px;
  margin-top: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  border: solid 1px #fff;
  font-style: normal;
  line-height: 2.93;
  letter-spacing: normal;
  color: #fff;
  cursor: pointer;
}

.ms-2 {
  margin-left: 2em !important;
}

.justify-content-space-around {
  justify-content: space-around !important;
}
.mb-40px {
  margin-bottom: 40px;
}

.checked-circle {
  width: auto;
  height: auto;
  padding: 7px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #1c40aa2a;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #1c3faa;
  }
}

.unchecked-circle{
  width: auto;
  height: auto;
  padding: 7px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #1c40aa2a;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    background-color: transparent;
  }
}
