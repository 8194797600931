.notificatation-wrapper {
  width: 100%;
  box-shadow: 0 3px 6px 0 rgb(44 40 40 / 11%);
  background-color: #ffffff;
  height: auto;
  margin-bottom: 32px;
  padding: 20px;
  border-radius: 5px;
  .notification-table {
    width: 100%;
  }
  .no-notification {
    font-size: 14px;
    margin: 0 10px 0 10px;
    text-align: center;
  }
}
