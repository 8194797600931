.dashboard-wrapper {
  width: 100%;
  box-shadow: 0 3px 6px 0 rgb(44 40 40 / 11%);
  background-color: #ffffff;
  height: auto;
  margin-bottom: 32px;
  border-radius: 5px;
  padding: 20px;

  .order-id{
    font-size: 18px;
  }

  .dashboard-title-container {
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .dashboard-title {
      display: block;
      flex-direction: row;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      font-family: Roboto-Regular;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.11;
      letter-spacing: normal;
      text-align: left;
      color: #172b4d;
      margin-bottom: 10px;

      .unsubscribe-text {
        display: block;
        font-size: 15px;
        color: inherit;
        width: 100%;
        margin-top: 10px;
        line-height: 1;
        span {
          padding: 0 3px 0 3px;
          text-decoration: underline;
          color: blue !important;
          cursor: pointer;
        }
      }
    }
  }
}
.dashboard-detail-wrapper {
  width: 100%;
  margin-top: 10px;
  .dashboard-table-wrapper {
    width: 100% !important;
    margin-top: 10px !important;
    display: block !important;
  }
}

.dspExportButton {
  display: flex;
  color: #000000;
  align-items: center;
  height: 30px;
  input {
    width: 15px;
    height: 15px;
  }
  label {
    padding-left: 10px;
    font-size: 14px;
  }
}

.dashboard-table-row {
  font-size: 15px;
  color: #000000;
  text-align: left;
  width: 100%;

  td {
    cursor: pointer;
    border-bottom: none !important;
  }
  
  .tickitIdHover {
    width: 5%;
    &:hover {
      transition: all 0.3s linear;
      color: #bfbfbf;
    }
  }

  .analyst-name {
    width: 20%;
  }

  .created-at {
    width: 20%;
    font-family: Roboto-Regular !important;
  }

  .organization-name {
    width: 20%;
  }

  .status {
    width: 15%;
  }

  .completion {
    width: 15%;
  }

  .hold-start {
    display: flex;
    justify-content: center;
  }
}
