.landing-wrapper {
  width: 100%;
  height: auto;
  background-color: #f1f5f8;
  padding-bottom: 30px;

  .athena-landing-bg{
    background-size: 100% 160%;
    height: 187px;
    background-position: 0 -126px;
    background-repeat: no-repeat;
    padding-top: 20px;
  }

  .athena-block {
    width: 100%;
    height: auto;
    padding-top: 20px;
    .athen-info-wrapper {
      width: 100%;
      padding: 0 10% 0 10%;
      display: flex;
      height: 250px;
      align-items: center;
      margin-top: 15px;
      .athena-image-container {
        width: 600px;
        height: auto;
        img {
          width: 100%;
          height: auto;
        }
      }
      // .special-width{
      //   width: 415px !important;
      // }
      .athena-text-container {
        height: 300px;
        padding-left: 20px;
        display: flex;
        align-items: flex-start;
        width: fit-content;

        .athena-header {
          padding-top: 40px;
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: #002147;
        }
        .athena-info-text {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #212529;
        }
      }
    }
  }

  .athena-offers {
    margin-top: 15px;
    color: #002147;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }

  .risk-assessment {
    margin-top: 40px;
    color: #212529;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }

  .product-block {
    width: 100%;
    height: auto;
    // margin-top: 25px;

    .product-switch.product-item {
      flex-direction: row-reverse;
    }

    .product-item {
      width: 100%;
      border-radius: 8px;
      height: 370px;
      display: flex;
      align-items: center;
      background-repeat: no-repeat;
      margin-top: 15px;
      background-size: 100% 100%;

      .detail-section {
        width: 45%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 3% 0 3%;
        .item-title {
          font-size: 30px;
          color: #ffffff;
          font-weight: bold;
          line-height: 1;
        }
        .item-description {
          margin-top: 15px;
          font-size: 15px;
          font-weight: 300;
          color: #ffffff;
          word-break: break-word;
        }

        .item-action {
          margin-top: 15px;
          display: flex;
          .try-now {
            border-radius: 10px;
            background-color: #ffffff;
            padding: 5px 15px 5px 15px;
            color: #183986;
            font-size: 11px;
            font-weight: 600;
            cursor: pointer;
            transition: all 0.3s linear;
            &:hover {
              background-color: #dddcdc9f;
            }
          }
          .request-demo {
            border-radius: 10px;
            background-color: #48a4d3;
            padding: 5px 15px 5px 15px;
            color: #ffffff;
            font-size: 11px;
            font-weight: 500;
            margin-left: 20px;
            transition: all 0.3s linear;
            cursor: pointer;
            &:hover {
              background-color: #3985aa9e;
            }
          }
        }
      }

      .image-section {
        width: 55%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .image-section.social-media-image {
        img {
          height: 99%;
          width: auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .landing-wrapper {
    .athena-info-text {
      font-size: 16px !important;
    }
    .athena-offers {
      margin-top: 30px;
      font-size: 36px;
    }
    .risk-assessment {
      margin-top: 60px;
      font-size: 22px;
    }
    .product-block {
      margin-top: 30px;
      .product-item {
        height: 450px;
        .detail-section {
          .item-title {
            font-size: 50px;
            // line-height: 0.5;
          }
          .item-description {
            font-size: 23px;
            line-height: 1;
            margin-top: 30px;
          }

          // .image-section {

          // }
        }
      }
    }
  }
}
